import "./accueil.css"
import { Link } from 'react-router-dom'
import { useEffect, useState } from "react"
import Header from "../../composants/header/header"
import Footer from "../../composants/footer/footer"
import EventReduit from "../../composants/events/eventReduit"
import axios from "axios"
import { Oval } from "react-loader-spinner"

function Accueil() {
	const [listeEvents, setListeEvents] = useState([]);
	const [chargementEvent, setChargementEvent] = useState(true);

	const getProchainsEvent = () => {
		axios.post("/event/getProchainsEvent", {nombre: 5}).then((res) => {
			setListeEvents(res.data.eventTrouve)
			setChargementEvent(false)
		}).catch(() => {setChargementEvent(false)})
	}

	useEffect(() => {
        getProchainsEvent()
    }, [])

	return (
		<>
			<Header />
			<main className="accueil">
				<div className="grandEncar">
					<h1>Games Beyond History</h1>
					<h2>Présentation du projet</h2>
					<p className="pPres">
						Ce projet regroupe une communauté grandissante de gens passionnés par l'histoire et les jeux vidéos. Nous nous rassemblons pour rejouer des batailles historiques au travers de jeux comme War Thunder, World Of Tanks, Enlisted ou encore World Of Warships.
					</p>
					<p>
						Rejoignez le serveur discord <a href="https://discord.gg/bwSrpXKuhx">Games Beyond History</a>.
					</p>
				</div>
				<div className="grandEncar">
					<Link className="liens animationLienFonce" to={`events`}><h2>Prochains événements</h2></Link>
					<div className="contenuEncar events">
						<Oval visible={chargementEvent} height="50" width="50" color="var(--main)" secondaryColor="var(--main-lighter)" ariaLabel="chargement" />
						{listeEvents.length != 0 ? listeEvents.map((event) => {
							return (
								<EventReduit key={event.id} idEvent={event.id} texte={event.event_texte} date={event.date_debut} jeu={event.jeu} lien={"/event/"+event.id} titre={event.titre} />
							)
						}) : !chargementEvent && <h3>Aucun événement prochainement.</h3>}
					</div>
				</div>
				<div className="grandEncar">
					<Link className="liens animationLienFonce" to={`https://www.youtube.com/@GamesBeyondHistory/videos`} target="_blank"><h2>Dernière vidéo</h2></Link>
					<div className="contenuEncar">
						<iframe className="videoYt" width="840" height="473" src="https://www.youtube.com/embed/o9ZFUUbI34Y?si=SiiBBWSnxLI3Dd91" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
					</div>
				</div>
			</main>
			<Footer />
		</>
	);
}

export default Accueil;
