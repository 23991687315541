import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom'
import AuthContext from '../../contexts/authcontext';
import "./header.css"
import Cookies from "js-cookie"
import logo from "../../medias/images/divers/logo-gbh.png"
import menuBtn from "../../medias/images/icones/menu.png"
import menuBtnFermer from "../../medias/images/icones/croix.png"
// import { slide as Menu } from 'react-burger-menu'

function Header() {
	const { isConnected, setIsConnected } = useContext(AuthContext);
	const deconnexion = () => {
		Cookies.remove("token")
		setIsConnected(false)
	}

	const [mobileMenu, setMobileMenu] = useState(false)
	return (
		<div className="header">
			<nav className='nav-desktop'>
				<Link className='lienDesktop liens animationLien' to={`/events`}>Événements</Link>
				{isConnected && <Link className='lienDesktop liens animationLien' to={`/liste-events`}>Liste Événements</Link>}
				<Link to={`/`}><img className='imgLogo' src={logo} alt="Logo" /></Link>
				{isConnected && <Link className='lienDesktop liens animationLien' to={`/liste-scenarios`}>Liste Scénarios</Link>}
				{isConnected ? (
				<Link onClick={deconnexion} className='lienDesktop liens animationLien' to={`/`}>Déconnexion</Link>
				) : (
				<Link className='lienDesktop liens animationLien' to={`/connexion`}>Connexion</Link>
				)}

				<img onClick={() => setMobileMenu(true)} src={menuBtn} className='boutonMenuOuvrir'/>

				{mobileMenu && <div className='menuMobile'>
					<img onClick={() => setMobileMenu(false)} src={menuBtnFermer} className='boutonMenuFermer' />
					<div className='contenuHeaderMobile'>
						<Link className='liens animationLien' to={`/`}>Accueil</Link>
						<Link className='liens animationLien' to={`/events`}>Événements</Link>
						{isConnected && <Link className='liens animationLien' to={`/liste-events`}>Liste Événements</Link>}
						{isConnected && <Link className='liens animationLien' to={`/liste-scenarios`}>Liste Scénarios</Link>}
						{isConnected ? (
						<Link onClick={deconnexion} className='liens animationLien' to={`/`}>Déconnexion</Link>
						) : (
						<Link className='liens animationLien' to={`/connexion`}>Connexion</Link>
						)}
					</div>
				</div>}
			</nav>
		</div>
	)
}

export default Header
